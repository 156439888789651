import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  Renderer2,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, Router } from '@angular/router';
import { Observable, Subject, Subscription, debounceTime, of } from 'rxjs';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
  FormControl,
  ValidationErrors,
  AbstractControl,
} from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { CountryService } from '../../../services/country.service';
import { WordingService } from '../../../services/wording.service';
import { ThemeService } from '../../../services/theme.service';
import { ValidationService } from '../../../services/validation.service';
import { LocalCacheService } from '../../../services/localCache.service';
import { PromotionService } from '../../../services/promotion.service';
import { QuoteService } from '../../../services/quote.service';
import { JsonDataService } from '../../../services/json-data.service';
import { SegmentService } from '../../../services/segment.service';

//import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormsModule } from '@angular/forms';
import { MessagesModule } from 'primeng/messages';
import { Rule } from '../../../interfaces/rule';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext';
import { KeyFilterModule } from 'primeng/keyfilter';
import { stateList } from '../../../utils/stateList';
import moment, { Moment } from 'moment';
import { TitleCasePipe } from '../../../utils/title-case.pipe';
import { environment } from '../../../../environments/environment';
import { FloatLabelModule } from 'primeng/floatlabel';
import { TooltipModule } from 'primeng/tooltip';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PDSService } from '../../../services/pds.service';
import { Utility } from '../../../utils/utils';
import { GlobalService } from '../../../services/global.service';

declare global {
  interface Window {
    analytics: any;
  }
}

@Component({
  selector: 'app-country-search',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    MatAutocompleteModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    InputSwitchModule,
    FormsModule,
    MessagesModule,
    MultiSelectModule,
    CalendarModule,
    InputTextModule,
    KeyFilterModule,
    FloatLabelModule,
    TooltipModule,
    NgSelectModule,
  ],
  providers: [TitleCasePipe],
  templateUrl: './country-search.component.html',
  styleUrl: './country-search.component.scss',
})
export class CountrySearchComponent implements OnInit, AfterViewInit {
  subscriptions: Subscription = new Subscription();
  singleTripForm: FormGroup = new FormGroup({});
  multiTripForm: FormGroup = new FormGroup({});
  groupedCountries: any[] = [];
  stateList: any[] = [];
  stateForm = this._formBuilder.group({
    stateGroup: '',
  });
  getaquotewording: string | undefined;
  checked = true;
  messages: any[] = [];
  primaryAgeMessage: string = '';
  secondaryAgeMessage: string = '';
  errorMessages: any = {};
  validationsList: any[] = [];
  activatedClub: string = '';
  selectedCountries: any = [];
  age1: any = '';
  age2: any = '';
  dependentsCount: any = '';
  state: any = null;
  promoCode: any = null;
  departureDate: any = null;
  returnDate: any = null;
  returnDateMulti: any = null;
  minReturnDate: Date = new Date();
  maxReturnDate: any;
  maxDepartDate: Date = new Date();
  minDepartDate: Date = new Date();
  minReturnDateMulti: Date = new Date();
  maxReturnDateMulti: any;
  countriesNotAllowedToTravel: any = [];
  isClubMember: boolean = environment.autoClub === 'W2C' ? false : true;
  promoCodeMessage: string = '';
  promoCodeMessageValidity: boolean = true;
  isSingleTrip: boolean = true;
  userSelectedReturn: boolean = false;
  showErrorAge1: any = false;
  showErrorAge2: any = false;
  showErrorAge1Multi: any = false;
  showErrorAge2Multi: any = false;
  defaultReturnDateValueIncrement: number = 0;
  firstMinAge: number = 0;
  firstMaxAge: number = 0;
  secondMinAge: number = 0;
  secondMaxAge: number = 0;
  cautionCountryMessage: any;
  cannotTravelDomesticInternationalMessage: any;
  cannotSelectDomesticAMTW2CMessage: any;
  travelDestinationRequiredMessage: any;
  invalidInputDepartMessage: any;
  departDateMoreThan18MonthsMessage: any;
  departDateBeforeTodayMessage: any;
  departDateRequiredMessage: any;
  departDateRequiredMessageMulti: any;
  invalidInputReturnMultiMessage: any;
  invalidInputReturnMessage: any;
  returnDateMoreThan365DaysMessage: any;
  returnDateBeforeDepartMessage: any;
  returnDateMoreThan60DaysMessage: any;
  returnBeforeDepartDateMultiMessage: any;
  datesEqualMultiMessage: any;
  returnBeforeDepartDateMessage: any;
  returnGreaterThanOneYearMessage: any;
  datesEqualMessage: any;
  returnRequiredMessage: any;
  stateRequiredMessage: any;
  maxDepartAMT: number = 60;
  maxReturnYear: number = 1;
  maxDepartDays: number = 548;
  isTooltipVisible: boolean = false;
  isTooltipVisibleTravDest: boolean = false;
  isTooltipVisibleDep: boolean = false;
  firstMinAgeMulti: number = 0;
  firstMaxAgeMulti: number = 0;
  secondMinAgeMulti: number = 0;
  secondMaxAgeMulti: number = 0;
  sanitizedHtml: SafeHtml | null = null;
  pdsLink: string = '';
  allCountries: any[] = [];
  ngSelectIsFocused: boolean = false;
  membershipSubtext: string = '';
  currentDepartureDate: string = '';
  currentReturnDate: string = '';
  showRequiredReturnDateMultiError: boolean = false;
  validatedPageCount: number = 0;
  searchTerms: any;

  expiredQuoteId: string = '';
  constructor(
    private countryService: CountryService,
    private _formBuilder: FormBuilder,
    private wordingService: WordingService,
    private themeService: ThemeService,
    private validationService: ValidationService,
    private localCacheService: LocalCacheService,
    private quoteService: QuoteService,
    private promotionService: PromotionService,
    private jsonDataService: JsonDataService,
    private router: Router,
    private titleCasePipe: TitleCasePipe,
    private elRef: ElementRef,
    private renderer: Renderer2,
    private segmentService: SegmentService,
    private sanitizer: DomSanitizer,
    private pdsService: PDSService,
    public globalService: GlobalService,
  ) {}

  getAgentInfo() {
    if (Utility.getUrlParameter('a')) {
      // Resume from PAS with agent info
      let agentGuid = this.globalService.getQueryParamInfo('AgentGuid');
      let branchGuid = this.globalService.getQueryParamInfo('BranchGuid');
      let agentName = this.globalService.getQueryParamInfo('AgentName');
      let agentBranchName = this.globalService.getQueryParamInfo('BranchName');

      if (agentGuid) {
        this.localCacheService.saveSessionStorage('agentGuid', agentGuid);
      }
      if (agentName) {
        this.localCacheService.saveSessionStorage('agentName', agentName);
      }
      if (branchGuid) {
        this.localCacheService.saveSessionStorage('branchGuid', branchGuid);
      }
    }
  }
  ngOnInit(): void {
    this.getAgentInfo();
    this.updateTooltipVisibility();
    this.activatedClub = environment.autoClub; //TO DO: CHANGE TO DYNAMIC HANDLING
    this.loadValidations();
    this.loadWordings();

    this.subscriptions.add(
      this.countryService.getCountries().subscribe((countries) => {
        this.allCountries = countries;
        this.allCountries.sort((a, b) => {
          return a.countryName.localeCompare(b.countryName);
        });
      }),
    );

    this.stateList = stateList;
    this.setDepartRange();
    this.createForm();
    if (this.departureDate) {
      this.setReturnDateAndRange();
    }

    const quoteEnquiry =
      this.localCacheService.getSessionStorage('quoteEnquiry');
    this.stateList = stateList;
    if (this.departureDate) {
      if (quoteEnquiry && quoteEnquiry.return) {
        this.returnDate = new Date(quoteEnquiry.return);
        this.returnDateMulti = new Date(quoteEnquiry.return);
        this.maxReturnDate = new Date(this.departureDate);
        this.maxReturnDate.setFullYear(
          this.maxReturnDate.getFullYear() + this.maxReturnYear,
        );
        this.maxReturnDate = new Date(
          this.maxReturnDate.getFullYear(),
          this.maxReturnDate.getMonth(),
          this.maxReturnDate.getDate(),
        );

        this.minReturnDate = new Date(
          this.departureDate.getFullYear(),
          this.departureDate.getMonth(),
          this.departureDate.getDate(),
        );

        this.maxReturnDateMulti = new Date(this.departureDate);
        this.maxReturnDateMulti.setFullYear(
          this.maxReturnDateMulti.getFullYear() + this.maxReturnYear,
        );
        this.maxReturnDateMulti = new Date(
          this.maxReturnDateMulti.getFullYear(),
          this.maxReturnDateMulti.getMonth(),
          this.maxReturnDateMulti.getDate(),
        );

        this.minReturnDateMulti = new Date(
          this.departureDate.getFullYear(),
          this.departureDate.getMonth(),
          this.departureDate.getDate(),
        );
      } else {
        this.setReturnDateAndRange();
      }
    }

    if (quoteEnquiry) {
      this.isSingleTrip = quoteEnquiry.isSingleTrip;
      const tripType = this.isSingleTrip ? 'Single' : 'Multiple';
      this.multiTripForm.patchValue({
        isSingleTrip: this.isSingleTrip,
        tripType: tripType,
      });
      this.singleTripForm.patchValue({
        isSingleTrip: this.isSingleTrip,
        tripType: tripType,
      });
    }

    // setup debouncer allow time for user fill out fields before triggering error message
    this.setupFieldValidationTimer(this.singleTripForm, true);
    this.setupFieldValidationTimer(this.multiTripForm, false);

    this.expiredQuoteId =
      this.localCacheService.getSessionStorage('expiredQuoteId');
    if (this.expiredQuoteId) {
      this.quoteService.getSubmission(this.expiredQuoteId).subscribe((data) => {
        if (
          data.policyStatus != 'P' &&
          data.policyStatus != 'A' &&
          data.policyStatus != 'N'
        ) {
          this.router.navigate(['/error']);
        } else {
          var days = Utility.dateDiffFromNow(data.pricingDate);
          let departureDate = this.parseEuropeanDate(data.fromDate);
          departureDate = new Date(departureDate);
          if (
            days > 14 &&
            data.policyStatus !== 'N' &&
            departureDate < this.minDepartDate
          ) {
            this.singleTripForm.controls['depart'].setErrors({
              invalidInputDepart: this.departDateBeforeTodayMessage,
            });
            this.singleTripForm.controls['return'].setValue('');
            this.multiTripForm.controls['depart'].setErrors({
              invalidInputDepart: this.departDateBeforeTodayMessage,
            });
            this.multiTripForm.controls['return'].setValue('');
            this.localCacheService.removeSessionStorage('quoteDetail');
          }
        }
      });
    }
  }

  ngAfterViewInit() {
    this.addCustomPlaceHolder();
  }

  addCustomPlaceHolder() {
    const targetElements = this.elRef.nativeElement.querySelectorAll(
      '.p-multiselect-label-container',
    );
    const multiSelectLabels = this.elRef.nativeElement.querySelectorAll(
      '.p-multiselect-label',
    );

    targetElements.forEach((targetElement: any, index: number) => {
      const existingDiv = targetElement.querySelector('.custom-placeholder');
      const multiSelectLabel = multiSelectLabels[index];

      if (this.selectedCountries && this.selectedCountries.length > 0) {
        if (!existingDiv) {
          const newDiv = this.renderer.createElement('div');
          const text = this.renderer.createText('Travel Destination *');

          this.renderer.appendChild(newDiv, text);
          this.renderer.addClass(newDiv, 'custom-placeholder'); // Add the CSS class

          this.renderer.appendChild(targetElement, newDiv);
        } else {
          this.renderer.removeClass(existingDiv, 'hidden');
        }

        if (multiSelectLabel) {
          this.renderer.removeClass(multiSelectLabel, 'reset');
          this.renderer.setStyle(multiSelectLabel, 'margin', '15px 0 0 0');
        }
      } else {
        if (existingDiv) {
          this.renderer.addClass(existingDiv, 'hidden');
        }

        if (multiSelectLabel) {
          this.renderer.addClass(multiSelectLabel, 'reset');
          this.renderer.setStyle(multiSelectLabel, 'margin', 'auto 0');
        }
      }
    });
  }

  addSelectedOption(event: any) {
    if (event) {
      this.singleTripForm.value.travelDestination.push(
        new FormControl(event.value),
      );
    }
  }

  createForm() {
    const quoteEnquiry =
      this.localCacheService.getSessionStorage('quoteEnquiry') || {};
    this.selectedCountries = quoteEnquiry.travelDestination;

    if (quoteEnquiry.depart) {
      this.departureDate = new Date(quoteEnquiry.depart);
    }

    if (quoteEnquiry.return) {
      this.returnDate = new Date(quoteEnquiry.return);
    }

    // If there's values in session use it as default
    this.age1 = quoteEnquiry.age1 ?? '';
    this.age2 = quoteEnquiry.age2 ?? '';
    this.dependentsCount = quoteEnquiry.dependentsCount ?? '';
    this.state = quoteEnquiry.state?.id ?? this.setStateByClub();
    this.promoCode = quoteEnquiry.promoCode ?? '';
    if (this.activatedClub !== 'W2C') {
      this.isClubMember =
        quoteEnquiry.isClubMember !== undefined &&
        quoteEnquiry.isClubMember !== null &&
        quoteEnquiry.isClubMember !== ''
          ? quoteEnquiry.isClubMember === 'true' ||
            quoteEnquiry.isClubMember === true
          : true;
    }

    // initial load and no data
    if (this.state === '' && this.activatedClub === 'RACV') {
      const racvDefaultState = this.stateList.filter(
        (x) => x.code === 'VIC',
      )[0];
      if (racvDefaultState) {
        this.state = racvDefaultState.id;
      }
    }

    this.singleTripForm = this._formBuilder.group(
      {
        travelDestination: [
          [],
          [
            Validators.required,
            this.cannotTravelValidator(),
            this.cannotTravelDomesticInternational(),
          ],
        ],
        depart: [[], [Validators.required]],
        return: [[], [Validators.required]],
        age1: ['', [Validators.required]],
        age2: [''],
        dependentsCount: ['', []],
        state: [5, [Validators.required]],
        promoCode: ['', []],
        tripType: ['Single'],
        isSingleTrip: [true],
        isClubMember: this.isClubMember,
      },
      {
        validators: [this.selectReturnDate('depart', 'return', 'isSingleTrip')],
      },
    );

    this.multiTripForm = this._formBuilder.group(
      {
        travelDestination: [[], [Validators.required]],
        depart: ['', [Validators.required]],
        return: ['', [Validators.required]],
        age1: ['', [Validators.required]],
        age2: [''],
        dependentsCount: ['', []],
        state: ['', [Validators.required]],
        promoCode: ['', []],
        tripType: ['Multiple'],
        isSingleTrip: [false],
        isClubMember: this.isClubMember,
      },
      {
        validators: [
          this.selectReturnDate('depart', 'return', 'isSingleTrip'),
          this.cannotTravelValidator(),
          this.cannotSelectDomesticAMTW2C(),
        ],
      },
    );
  }

  setStateByClub(): any {
    if (environment.autoClub === 'RACV') {
      return 5;
    } else if (environment.autoClub === 'AANT') {
      return 8;
    } else if (environment.autoClub === 'RACQ') {
      return 2;
    } else if (environment.autoClub === 'RACT') {
      return 4;
    } else if (environment.autoClub === 'RAC') {
      return 6;
    } else if (environment.autoClub === 'RAA') {
      return 3;
    } else {
      return '';
    }
  }

  validatePromoCode(event: any) {
    if (event.target.value) {
      this.subscriptions.add(
        this.promotionService
          .validatePromoCode(event.target.value)
          .subscribe((promoCode) => {
            if (promoCode && promoCode.isValid) {
              this.promoCodeMessage =
                this.errorMessages['promoCode']?.['promoCodeValid'][0] +
                promoCode.promoCode +
                this.errorMessages['promoCode']?.['promoCodeValid'][1] +
                promoCode.discountRate.toFixed(2) +
                this.errorMessages['promoCode']?.['promoCodeValid'][2];
              this.promoCodeMessageValidity = true;
            } else {
              this.promoCodeMessage =
                this.errorMessages['promoCode']?.['promoCodeInvalid'];
              this.promoCodeMessageValidity = false;
            }
          }),
      );
    } else {
      this.promoCodeMessage = '';
    }
  }

  setDepartRange() {
    this.maxDepartDate.setDate(
      this.maxDepartDate.getDate() + this.maxDepartDays,
    );

    this.maxDepartDate = new Date(
      this.maxDepartDate.getFullYear(),
      this.maxDepartDate.getMonth(),
      this.maxDepartDate.getDate(),
    );

    this.minDepartDate = new Date(
      this.minDepartDate.getFullYear(),
      this.minDepartDate.getMonth(),
      this.minDepartDate.getDate(),
    );
  }

  setReturnDateAndRange() {
    if (this.departureDate) {
      this.showRequiredReturnDateMultiError = false;
      this.currentDepartureDate = moment(this.departureDate).format(
        'DD/MM/YYYY',
      );
      this.returnDateMulti = new Date(this.departureDate);
      this.returnDateMulti.setFullYear(this.returnDateMulti.getFullYear() + 1);
      this.returnDateMulti = new Date(
        this.returnDateMulti.setDate(this.returnDateMulti.getDate() - 1),
      );

      if (this.userSelectedReturn === false) {
        // pre-fill return date
        this.returnDate = new Date(this.departureDate);
        this.returnDate.setDate(
          new Date(
            this.departureDate.getDate() + this.defaultReturnDateValueIncrement,
          ),
        );
      }
      // Sets return date range as it depends on departure date
      this.maxReturnDate = new Date(this.departureDate);
      this.maxReturnDate.setFullYear(
        this.maxReturnDate.getFullYear() + this.maxReturnYear,
      );
      this.maxReturnDate = new Date(
        this.maxReturnDate.getFullYear(),
        this.maxReturnDate.getMonth(),
        this.maxReturnDate.getDate(),
      );

      this.minReturnDate = new Date(
        this.departureDate.getFullYear(),
        this.departureDate.getMonth(),
        this.departureDate.getDate(),
      );
    }
  }

  parseEuropeanDate(input: string): Date {
    const parts = input.split('/');
    if (parts.length !== 3) {
      throw new Error('Invalid date format. Please use DD/MM/YYYY.');
    }
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Month is 0-indexed in JavaScript, so 1 needs to be subtracted
    const year = parseInt(parts[2], 10);

    const date = new Date(year, month, day);
    if (isNaN(date.getTime())) {
      throw new Error('Invalid date. Please check the values provided.');
    }
    return date;
  }

  inputDepartValidation(event: any) {
    this.showRequiredReturnDateMultiError = false;
    if (
      !event.target.value ||
      (!this.singleTripForm.get('depart')?.valid &&
        !this.multiTripForm.get('depart')?.valid)
    ) {
      return;
    }
    let departureDate = event.target.value;
    const departDate = Utility.convertToDate(event.target.value);
    const date = moment(departDate).format('DD/MM/YYYY');

    if (date === this.currentDepartureDate) {
      // to prevent double clicking of calendar to select
      return;
    }
    if (date != null && date != '') {
      let isValidDate = moment(departDate, 'DD/MM/YYYY', true).isValid();
      if (!isValidDate) {
        this.singleTripForm.controls['depart'].setErrors({
          invalidInputDepart: this.invalidInputDepartMessage,
        });
        this.multiTripForm.controls['depart'].setErrors({
          invalidInputDepart: this.invalidInputDepartMessage,
        });
        this.returnDate = '';
        this.returnDateMulti = '';
      } else {
        // covers scenario where user types in date return date range is set
        departureDate = this.parseEuropeanDate(date);
        departureDate = new Date(departureDate);
        this.currentDepartureDate = moment(this.departureDate).format(
          'DD/MM/YYYY',
        );
        this.singleTripForm.get('depart')?.setValue(departureDate);
        this.multiTripForm.get('depart')?.setValue(departureDate);
        // this.departureDate = departureDate;
        // Typing a date that is in the past
        if (departureDate < this.minDepartDate) {
          this.singleTripForm.controls['depart'].setErrors({
            departDateBeforeToday: this.departDateBeforeTodayMessage,
          });
          this.multiTripForm.controls['depart'].setErrors({
            departDateBeforeToday: this.departDateBeforeTodayMessage,
          });
          this.returnDate = '';
          this.returnDateMulti = '';
        }
        // Typing in a date that is more than 18 months from today
        else if (departureDate > this.maxDepartDate) {
          this.singleTripForm.controls['depart'].setErrors({
            departDateMoreThan18Months: this.departDateMoreThan18MonthsMessage,
          });
          this.multiTripForm.controls['depart'].setErrors({
            departDateMoreThan18Months: this.departDateMoreThan18MonthsMessage,
          });
          this.returnDate = '';
          this.returnDateMulti = '';
        } else {
          this.singleTripForm.controls['depart'].setErrors(null);
          this.multiTripForm.controls['depart'].setErrors(null);

          // Sets the return date and range
          // pre-fill return date
          if (this.userSelectedReturn === false) {
            this.returnDate = new Date(departureDate);
            this.returnDate.setDate(
              new Date(
                departureDate.getDate() + this.defaultReturnDateValueIncrement,
              ),
            );
            this.currentReturnDate = moment(this.returnDate).format(
              'DD/MM/YYYY',
            );
          }
          if (!this.singleTripForm.get('isSingleTrip')?.value) {
            this.returnDateMulti = new Date(this.departureDate);
            this.returnDateMulti.setFullYear(
              this.returnDateMulti.getFullYear() + 1,
            );
            this.returnDateMulti = new Date(
              this.returnDateMulti.setDate(this.returnDateMulti.getDate() - 1),
            );
            this.currentReturnDate = moment(this.returnDateMulti).format(
              'DD/MM/YYYY',
            );
          }

          // Sets return date range as it depends on departure date
          this.maxReturnDate = new Date(this.departureDate);
          this.maxReturnDate.setFullYear(
            this.maxReturnDate.getFullYear() + this.maxReturnYear,
          );
          this.maxReturnDate = new Date(
            this.maxReturnDate.getFullYear(),
            this.maxReturnDate.getMonth(),
            this.maxReturnDate.getDate(),
          );

          this.minReturnDate = new Date(
            this.departureDate.getFullYear(),
            this.departureDate.getMonth(),
            this.departureDate.getDate(),
          );

          this.maxReturnDateMulti = new Date(this.departureDate);
          this.maxReturnDateMulti.setFullYear(
            this.maxReturnDateMulti.getFullYear() + this.maxReturnYear,
          );
          this.maxReturnDateMulti = new Date(
            this.maxReturnDateMulti.getFullYear(),
            this.maxReturnDateMulti.getMonth(),
            this.maxReturnDateMulti.getDate(),
          );

          this.minReturnDateMulti = new Date(
            this.departureDate.getFullYear(),
            this.departureDate.getMonth(),
            this.departureDate.getDate(),
          );
        }
      }
    }
  }

  inputReturnValidation(event: any, tripType: any) {
    let returnDate = event.target.value;
    if (
      !returnDate ||
      !this.singleTripForm.get('return')?.valid ||
      returnDate === moment(this.returnDate).format('DD/MM/YYYY')
    ) {
      return;
    }
    if (returnDate != '') {
      this.userSelectedReturn = true;
    }

    const retDate = Utility.convertToDate(event.target.value);
    const date = moment(retDate).format('DD/MM/YYYY');
    if (date != null && date != '') {
      let isValidDate = moment(retDate, 'DD/MM/YYYY', true).isValid();
      if (!isValidDate) {
        if (tripType === 'singleTrip') {
          this.singleTripForm.controls['return'].setErrors({
            invalidInputReturn: this.invalidInputReturnMessage,
          });
        }
      } else {
        // covers scenario where user types in date return date range is set
        returnDate = this.parseEuropeanDate(date);
        returnDate = new Date(returnDate);
        this.currentReturnDate = moment(returnDate).format('DD/MM/YYYY');
        this.singleTripForm.get('return')?.setValue(returnDate);
        // this.returnDate = returnDate;
        // Typing a date that is in the past
        this.minReturnDate.setHours(0, 0, 0, 0);

        if (
          returnDate < this.minReturnDate &&
          tripType === 'singleTrip' &&
          !this.singleTripForm.errors?.['returnBeforeDepartDate']
        ) {
          this.singleTripForm.controls['return'].setErrors({
            returnDateBeforeDepart: this.returnDateBeforeDepartMessage,
          });
        }
        // For Single trip - Return date that is over a year from the departure
        else if (
          returnDate > this.maxReturnDate &&
          tripType === 'singleTrip' &&
          !this.singleTripForm.errors?.['returnGreaterThanOneYear']
        ) {
          this.singleTripForm.controls['return'].setErrors({
            returnDateMoreThan365Days: this.returnDateMoreThan365DaysMessage,
          });
        }
        // no errors
        else if (tripType === 'singleTrip') {
          this.singleTripForm.controls['return'].setErrors(null);
        }
      }
    }
  }

  // Return date selected before depart date and return date is earlier than depart date
  selectReturnDate(from: string, to: string, isSingleTrip: string) {
    return (group: FormGroup): { [key: string]: any } | null => {
      const controlFrom = group.controls[from];
      const controlTo = group.controls[to];
      const isSingleTripControl = group.controls[isSingleTrip];
      if (
        !controlFrom ||
        !controlTo ||
        !controlFrom.value ||
        !controlTo.value
      ) {
        // If either control does not exist or does not have a value, validation is bypassed
        return null;
      }
      let isValidDate = moment(controlTo.value, 'DD/MM/YYYY', true).isValid();
      if (isValidDate) {
        const fromDate = new Date(controlFrom.value);
        const toDate = new Date(controlTo.value);
        this.currentReturnDate = moment(toDate).format('DD/MM/YYYY');
        let maxReturnDate = new Date(fromDate);
        maxReturnDate = new Date(
          maxReturnDate.setFullYear(
            fromDate.getFullYear() + this.maxReturnYear,
          ),
        );

        let limitfromDate = new Date(fromDate);
        limitfromDate.setDate(limitfromDate.getDate() + this.maxDepartAMT);
        let isAMTReturnGreater60Days = toDate > limitfromDate;

        if (fromDate > toDate && fromDate && toDate) {
          if (isSingleTripControl.value) {
            return {
              returnBeforeDepartDate: this.returnBeforeDepartDateMessage,
            };
          }
        } else if (toDate > maxReturnDate && fromDate && toDate) {
          if (isSingleTripControl.value) {
            return {
              returnGreaterThanOneYear: this.returnGreaterThanOneYearMessage,
            };
          }
        } else if (
          fromDate.getDate() === toDate.getDate() &&
          fromDate.getMonth() === toDate.getMonth() &&
          fromDate.getFullYear() === toDate.getFullYear()
        ) {
          if (isSingleTripControl.value) {
            return {
              datesEqual: this.datesEqualMessage,
            };
          }
        }
      }
      return {};
    };
  }

  cannotTravelValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.selectedCountries && this.selectedCountries.length > 0) {
        let adviceMessage = {} as any;
        // Check if there are countries that is considered as sanctioned
        const cautionCountries = this.selectedCountries
          .filter(
            (country: any) =>
              country.allowTravel === false &&
              country.isCautionCountry === true,
          )
          .map((country: any) => country.countryName)
          .join(',');
        if (cautionCountries.length > 0) {
          adviceMessage.cautionCountry = `You are unable to complete your quote for the following destination(s): ${cautionCountries}`;
        }

        // Check if there are countries that is considered as do not travel
        this.countriesNotAllowedToTravel = this.selectedCountries
          .filter(
            (country: any) =>
              country.allowTravel === false && country.isDNTCountry === true,
          )
          .map((country: any) => country.countryName);

        if (this.countriesNotAllowedToTravel.length > 0) {
          adviceMessage.cannotTravelCountry =
            this.errorMessages['countries']?.['cannotTravelCountry'][0] +
            this.countriesNotAllowedToTravel +
            this.errorMessages['countries']?.['cannotTravelCountry'][1];
        }
        return adviceMessage;
      }
      return null; // no errors
    };
  }

  cannotTravelDomesticInternational() {
    return (group: FormGroup): ValidationErrors | null => {
      if (this.selectedCountries && this.selectedCountries.length > 0) {
        let isDomestic = this.selectedCountries[0].isDomestic;
        // Check if there are countries that is considered as do not travel
        let validToTravel = this.selectedCountries.every(
          (country: any) => country.isDomestic === isDomestic,
        );
        if (!validToTravel)
          return {
            cannotTravelDomesticInternational:
              this.cannotTravelDomesticInternationalMessage,
          };
      }
      return null; // no errors
    };
  }

  // This rule only applies to W2C AMT where you cannot select Domestic in AMT. Besides from W2C, clubs allow Domestic AMT
  cannotSelectDomesticAMTW2C() {
    return (group: FormGroup): ValidationErrors | null => {
      if (this.activatedClub === 'W2C') {
        if (this.selectedCountries && this.selectedCountries.length > 0) {
          let validToTravel = this.selectedCountries.every(
            (country: any) => country.isDomestic === false,
          );
          if (!validToTravel)
            return {
              cannotSelectDomesticAMTW2C:
                this.cannotSelectDomesticAMTW2CMessage,
            };
        }
      }
      return null; // no errors
    };
  }

  patchForm() {
    console.log('patchForm', this.selectedCountries);
    this.singleTripForm.patchValue({
      travelDestination: this.selectedCountries,
    });
    this.multiTripForm.patchValue({
      travelDestination: this.selectedCountries,
    });
    this.addCustomPlaceHolder();

    // this.hideOverlay();//ADD THIS CALL IF YOU WISH TO CLOSE THE COUNTRY SELECTION UPON CLICK
  }

  hideOverlay() {
    const overlay = this.elRef.nativeElement.querySelector('.p-overlay');
    if (overlay) {
      overlay.style.display = 'none';
    }
  }

  onSingleTripClick() {
    this.patchForm();
    this.singleTripForm.patchValue({
      isSingleTrip: true,
      tripType: 'Single',
    });
    this.multiTripForm.patchValue({
      isSingleTrip: true,
      tripType: 'Single',
    });
    this.setReturnDateAndRange();
  }

  getAgeMessage(traveller: any, field: any, message: any, displayMessage: any) {
    if (traveller == 'first_age' && field == 'first_age') {
      this.primaryAgeMessage = displayMessage == true ? message : '';
    } else if (traveller == 'second_age' && field == 'second_age') {
      this.secondaryAgeMessage = displayMessage == true ? message : '';
    }
  }

  setupFieldValidationTimer(form: any, isSingleTrip: boolean) {
    if (isSingleTrip) {
      this.subscriptions.add(
        form
          .get('age1')
          ?.valueChanges.pipe(debounceTime(800))
          .subscribe(() => {
            this.showErrorAge1 = form.get('age1')?.invalid;
          }),
      );

      this.subscriptions.add(
        form
          .get('age2')
          ?.valueChanges.pipe(debounceTime(800))
          .subscribe(() => {
            this.showErrorAge2 = form.get('age2')?.invalid;
          }),
      );
    } else {
      this.subscriptions.add(
        form
          .get('age1')
          ?.valueChanges.pipe(debounceTime(800))
          .subscribe(() => {
            this.showErrorAge1Multi = form.get('age1')?.invalid;
          }),
      );

      this.subscriptions.add(
        form
          .get('age2')
          ?.valueChanges.pipe(debounceTime(800))
          .subscribe(() => {
            this.showErrorAge2Multi = form.get('age2')?.invalid;
          }),
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onSingleTripSubmit(route?: string) {
    this.patchForm();
    if (this.singleTripForm.valid) {
      if (this.expiredQuoteId) {
        this.localCacheService.removeSessionStorage('expiredQuoteId');
      }
      // Segment
      const singleTripDetails = {
        eventName: 'home_page_single_trip_get_a_quote',
        tripType: this.singleTripForm.value.tripType,
        selectedCountries: this.selectedCountries,
        departureDate: this.departureDate,
        returnDate: this.returnDate,
        age1: this.age1,
        age2: this.age2,
        dependentsCount: this.dependentsCount,
        state: this.state,
        promoCode: this.promoCode,
        clubMember: this.isClubMember,
        valid: true,
      };

      this.segmentService.segmentIdentifyHomePageQuoteButton(singleTripDetails);
      let quoteEnquiryObject = this.singleTripForm.value;
      quoteEnquiryObject.state = this.stateList.find(
        (state) => state.id == quoteEnquiryObject.state,
      );
      this.localCacheService.saveSessionStorage(
        'quoteEnquiry',
        quoteEnquiryObject,
      );
      const quoteDetail =
        this.localCacheService.getSessionStorage('quoteDetail');
      if (!quoteDetail?.quoteNumber) {
        this.subscriptions.add(
          this.quoteService
            .getInitialQuote(quoteEnquiryObject)
            .subscribe((response) => {
              if (response?.error && response.error) {
                throw response;
              } else {
                // console.log('initial quote response', response);
                this.localCacheService.saveSessionStorage(
                  'quoteDetail',
                  response,
                );
                this.localCacheService.removeSessionStorage('pricingDetail');
                this.globalService.checkAndValidatePage(0, true);
                this.router.navigate(['/quotes-steps-one']);
              }
            }),
        );
      } else {
        this.subscriptions.add(
          this.quoteService
            .submitQuote(quoteEnquiryObject, 'home')
            .subscribe((response: any) => {
              if (response?.error && response.error) {
                throw response;
              } else {
                // console.log('submit quote response', response);
                this.localCacheService.saveSessionStorage(
                  'quoteDetail',
                  response,
                );
                this.localCacheService.removeSessionStorage('pricingDetail');
                this.globalService.checkAndValidatePage(0, true);
                this.router.navigate(['/quotes-steps-one']);
              }
            }),
        );
      }
    } else {
      // Segment
      const singleTripDetails = {
        eventName: 'home_page_single_trip_get_a_quote',
        tripType: this.singleTripForm.value.tripType,
        selectedCountries: this.selectedCountries,
        departureDate: this.departureDate,
        returnDate: this.returnDate,
        age1: this.age1,
        age2: this.age2,
        dependentsCount: this.dependentsCount,
        state: this.state,
        promoCode: this.promoCode,
        clubMember: this.isClubMember,
        valid: false,
      };

      this.segmentService.segmentIdentifyHomePageQuoteButton(singleTripDetails);
      this.singleTripForm.markAllAsTouched();
    }
  }

  onMultiTripSubmit() {
    this.patchForm();
    this.showRequiredReturnDateMultiError = false;
    if (this.multiTripForm.valid) {
      if (this.expiredQuoteId) {
        this.localCacheService.removeSessionStorage('expiredQuoteId');
      }
      // Segment
      const amtTripDetails = {
        eventName: 'home_page_amt_trip_get_a_quote',
        tripType: this.singleTripForm.value.tripType,
        selectedCountries: this.selectedCountries,
        departureDate: this.departureDate,
        returnDate: this.returnDate,
        age1: this.age1,
        age2: this.age2,
        dependentsCount: this.dependentsCount,
        state: this.state,
        promoCode: this.promoCode,
        clubMember: this.isClubMember,
        valid: true,
      };

      this.segmentService.segmentIdentifyHomePageQuoteButton(amtTripDetails);
      let returnDateAMT = new Date(this.departureDate);
      returnDateAMT.setFullYear(returnDateAMT.getFullYear() + 1);
      returnDateAMT = new Date(
        returnDateAMT.setDate(returnDateAMT.getDate() - 1),
      );

      let quoteEnquiryObject = this.multiTripForm.value;

      quoteEnquiryObject.return = returnDateAMT;
      quoteEnquiryObject.state = this.stateList.find(
        (state) => state.id == quoteEnquiryObject.state,
      );
      this.localCacheService.saveSessionStorage(
        'quoteEnquiry',
        quoteEnquiryObject,
      );
      const quoteDetail =
        this.localCacheService.getSessionStorage('quoteDetail');
      if (!quoteDetail?.quoteNumber) {
        this.subscriptions.add(
          this.quoteService
            .getInitialQuote(quoteEnquiryObject)
            .subscribe((response) => {
              if (response?.error && response.error) {
                throw response;
              } else {
                this.localCacheService.saveSessionStorage(
                  'quoteDetail',
                  response,
                );
                this.localCacheService.removeSessionStorage('pricingDetail');
                if (response?.status && response?.status != 200) {
                } else {
                  this.globalService.checkAndValidatePage(0, true);
                  this.router.navigate(['/quotes-steps-one']);
                }
              }
            }),
        );
      } else {
        this.subscriptions.add(
          this.quoteService
            .submitQuote(quoteEnquiryObject, 'home')
            .subscribe((response) => {
              if (response?.error && response.error) {
                throw response;
              } else {
                if (!response.error) {
                  this.localCacheService.saveSessionStorage(
                    'quoteDetail',
                    response,
                  );
                  this.localCacheService.removeSessionStorage('pricingDetail');
                  this.globalService.checkAndValidatePage(0, true);
                  this.router.navigate(['/quotes-steps-one']);
                }
              }
            }),
        );
      }
      // this.router.navigate(['/quotes-steps-one']);
    } else {
      // Segment
      const amtTripDetails = {
        eventName: 'home_page_amt_trip_get_a_quote',
        tripType: this.singleTripForm.value.tripType,
        selectedCountries: this.selectedCountries,
        departureDate: this.departureDate,
        returnDate: this.returnDate,
        age1: this.age1,
        age2: this.age2,
        dependentsCount: this.dependentsCount,
        state: this.state,
        promoCode: this.promoCode,
        clubMember: this.isClubMember,
        valid: false,
      };

      this.segmentService.segmentIdentifyHomePageQuoteButton(amtTripDetails);
      this.multiTripForm.markAllAsTouched();
    }
  }

  onAMTSelect() {
    this.patchForm();
    this.multiTripForm.patchValue({
      isSingleTrip: false,
      tripType: 'Multiple',
    });
    this.singleTripForm.patchValue({
      isSingleTrip: false,
      tripType: 'Multiple',
    });
    this.setReturnDateAndRange();
  }

  loadValidations() {
    const validationServiceSub = this.validationService
      //TO DO: CHANGE TO DYNAMIC HANDLING
      .clubValidation()
      .subscribe((response) => {
        this.validationsList = response;
        this.prepareErrorMessages();
        this.setDefaultValuesForValidationRules(response);
      });

    this.subscriptions.add(validationServiceSub);
  }

  prepareErrorMessages() {
    this.validationsList.forEach((validation) => {
      if (!this.errorMessages[validation.field]) {
        this.errorMessages[validation.field] = {};
      }

      validation.rules.forEach((rule: Rule) => {
        this.errorMessages[validation.field][rule.type] = rule.message;
      });
    });
  }

  validateAge(traveller: any, event: any) {
    this.subscriptions.add(
      this.validationService.clubValidation().subscribe((data) => {
        for (const validation of data) {
          for (const rule of validation.rules) {
            if (
              rule.type === 'min' &&
              event.target.value != '' &&
              event.target.value < rule.value &&
              validation.field == traveller
            ) {
              this.getAgeMessage(
                traveller,
                validation.field,
                rule.message,
                true,
              );
              break;
            } else if (
              rule.type === 'max' &&
              event.target.value > rule.value &&
              validation.field == traveller
            ) {
              this.getAgeMessage(
                traveller,
                validation.field,
                rule.message,
                true,
              );
              break;
            } else if (
              rule.type === 'required' &&
              event.target.value.length < 1 &&
              validation.field == 'first_age'
            ) {
              this.getAgeMessage(
                traveller,
                validation.field,
                rule.message,
                true,
              );
              break;
            } else {
              this.getAgeMessage(
                traveller,
                validation.field,
                rule.message,
                false,
              );
            }
          }
        }
      }),
    );
  }

  setDefaultValuesForValidationRules(validationsList: any[]) {
    validationsList.forEach((validation: any) => {
      if (validation.field === 'returnDateDefaultValue') {
        validation.rules.forEach((rule: Rule) => {
          if (rule.type === 'defaultValue') {
            this.defaultReturnDateValueIncrement = rule.value;
            return;
          }
        });
      } else if (validation.field === 'first_age') {
        validation.rules.forEach((rule: Rule) => {
          if (rule.type === 'min') {
            this.firstMinAge = rule.value;
          } else if (rule.type === 'max') {
            this.firstMaxAge = rule.value;
          }
        });
      } else if (validation.field === 'second_age') {
        validation.rules.forEach((rule: Rule) => {
          if (rule.type === 'min') {
            this.secondMinAge = rule.value;
          }
          if (rule.type === 'max') {
            this.secondMaxAge = rule.value;
          }
        });
      } else if (validation.field === 'first_age_multi') {
        validation.rules.forEach((rule: Rule) => {
          if (rule.type === 'min') {
            this.firstMinAgeMulti = rule.value;
          }
          if (rule.type === 'max') {
            this.firstMaxAgeMulti = rule.value;
          }
        });
      } else if (validation.field === 'second_age_multi') {
        validation.rules.forEach((rule: Rule) => {
          if (rule.type === 'min') {
            this.secondMinAgeMulti = rule.value;
          }
          if (rule.type === 'max') {
            this.secondMaxAgeMulti = rule.value;
          }
        });
      } else if (validation.field === 'countries') {
        validation.rules.forEach((rule: Rule) => {
          if (rule.type === 'cautionCountry') {
            this.cautionCountryMessage = rule.message;
          } else if (rule.type === 'cannotTravelDomesticInternational') {
            this.cannotTravelDomesticInternationalMessage = rule.message;
          } else if (rule.type === 'cannotSelectDomesticAMTW2C') {
            this.cannotSelectDomesticAMTW2CMessage = rule.message;
          } else if (rule.type === 'travelDestinationRequired') {
            this.travelDestinationRequiredMessage = rule.message;
          }
        });
      } else if (validation.field === 'depart') {
        validation.rules.forEach((rule: Rule) => {
          if (rule.type === 'invalidInputDepart') {
            this.invalidInputDepartMessage = rule.message;
          } else if (rule.type === 'departDateMoreThan18Months') {
            this.departDateMoreThan18MonthsMessage = rule.message;
          } else if (rule.type === 'departDateBeforeToday') {
            this.departDateBeforeTodayMessage = rule.message;
          } else if (rule.type === 'required') {
            this.departDateRequiredMessage = rule.message;
          }
        });
      } else if (validation.field === 'return') {
        validation.rules.forEach((rule: Rule) => {
          if (rule.type === 'invalidInputReturn') {
            this.invalidInputReturnMessage = rule.message;
          } else if (rule.type === 'invalidInputReturnMulti') {
            this.invalidInputReturnMultiMessage = rule.message;
          } else if (rule.type === 'returnDateMoreThan365Days') {
            this.returnDateMoreThan365DaysMessage = rule.message;
          } else if (rule.type === 'returnDateBeforeDepart') {
            this.returnDateBeforeDepartMessage = rule.message;
          } else if (rule.type === 'returnDateMoreThan60Days') {
            this.returnDateMoreThan60DaysMessage = rule.message;
          } else if (rule.type === 'returnBeforeDepartDateMulti') {
            this.returnBeforeDepartDateMultiMessage = rule.message;
          } else if (rule.type === 'datesEqualMulti') {
            this.datesEqualMultiMessage = rule.message;
          } else if (rule.type === 'returnBeforeDepartDate') {
            this.returnBeforeDepartDateMessage = rule.message;
          } else if (rule.type === 'returnGreaterThanOneYear') {
            this.returnGreaterThanOneYearMessage = rule.message;
          } else if (rule.type === 'datesEqual') {
            this.datesEqualMessage = rule.message;
          } else if (rule.type === 'required') {
            this.returnRequiredMessage = rule.message;
          }
        });
      } else if (validation.field === 'depart_multi') {
        validation.rules.forEach((rule: Rule) => {
          if (rule.type === 'invalidInputDepart') {
            this.invalidInputDepartMessage = rule.message;
          } else if (rule.type === 'departDateMoreThan18Months') {
            this.departDateMoreThan18MonthsMessage = rule.message;
          } else if (rule.type === 'departDateBeforeToday') {
            this.departDateBeforeTodayMessage = rule.message;
          } else if (rule.type === 'required') {
            this.departDateRequiredMessageMulti = rule.message;
          }
        });
      } else if (validation.field === 'state') {
        validation.rules.forEach((rule: Rule) => {
          if (rule.type === 'required') {
            this.stateRequiredMessage = rule.message;
          }
        });
      }
    });
    this.singleTripForm
      .get('age1')
      ?.addValidators([
        Validators.min(this.firstMinAge),
        Validators.max(this.firstMaxAge),
      ]);
    this.singleTripForm
      .get('age2')
      ?.addValidators([
        Validators.min(this.secondMinAge),
        Validators.max(this.secondMaxAge),
      ]);
    this.multiTripForm
      .get('age1')
      ?.addValidators([
        Validators.min(this.firstMinAgeMulti),
        Validators.max(this.firstMaxAgeMulti),
      ]);
    this.multiTripForm
      .get('age2')
      ?.addValidators([
        Validators.min(this.secondMinAgeMulti),
        Validators.max(this.secondMaxAgeMulti),
      ]);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.updateTooltipVisibility();
  }

  toggleTooltip() {
    this.isTooltipVisible = !this.isTooltipVisible;
  }

  private updateTooltipVisibility() {
    this.isTooltipVisible = window.innerWidth < 768;
    this.isTooltipVisibleTravDest = window.innerWidth < 768;
    this.isTooltipVisibleDep = window.innerWidth < 768;
  }

  private loadWordings() {
    this.wordingService.getWordings().subscribe((wordings) => {
      let link = '';
      let linkText = '';
      let disclaimer = '';

      wordings.forEach((wording) => {
        if (wording.field === 'disclaimer') {
          disclaimer = wording.value;
        }
        if (wording.field === 'fullDisclaimerLink') {
          link = wording.value;
        }
        if (wording.field === 'fullDisclaimerLinkText') {
          linkText = wording.value;
        }
        if (wording.field === 'membershipSubtext') {
          this.membershipSubtext = wording.value;
        }
      });
      let href = '';
      switch (this.activatedClub.toUpperCase()) {
        case 'W2C':
          href = `<a rel="noopener noreferrer" style="color: #2E486A !important;" class="underline text-light" target="_blank" href="${link}"><strong>${linkText}</strong></a>`;
          disclaimer = disclaimer.replace('##linkText##', href);
          this.sanitizedHtml =
            this.sanitizer.bypassSecurityTrustHtml(disclaimer);
          break;
        case 'RACV':
        case 'RACQ':
        case 'RACT':
        case 'RAA':
        case 'RAC':
        case 'AANT':
          this.loadPdsToDisclaimer(disclaimer, linkText);
          break;
      }
    });
  }
  loadPdsToDisclaimer(disclaimer: string, linkText: string) {
    const currentLatestPDS =
      this.localCacheService.getSessionStorage('lastestPDS');
    if (!currentLatestPDS) {
      this.pdsService.getLatestPDS().subscribe((data) => {
        const href = `<a rel="noopener noreferrer" style="color: #2E486A !important;" class="underline text-light" target="_blank" href=${data?.pds[0].previewUrl}><strong>${linkText}</strong></a>`;
        disclaimer = disclaimer.replace('##linkText##', href);
        this.sanitizedHtml = this.sanitizer.bypassSecurityTrustHtml(disclaimer);
      });
    } else {
      const href = `<a rel="noopener noreferrer" style="color: #2E486A !important;" class="underline text-light" target="_blank" href=${currentLatestPDS?.previewUrl}><strong>${linkText}</strong></a>`;
      disclaimer = disclaimer.replace('##linkText##', href);
      this.sanitizedHtml = this.sanitizer.bypassSecurityTrustHtml(disclaimer);
    }
  }

  searchFn(term: string, item: any) {
    if (term.length < 2) return false;
    return item.countryName.toLowerCase().startsWith(term.toLowerCase());
  }
  onModelChange(event: any) {
    this.selectedCountries = event;
    this.searchTerms = '';
    this.patchForm();
  }

  countrySearchFn(searchTerms: any) {
    this.searchTerms = searchTerms?.term;
  }

  handlecountrySearcBlur(event: any) {
    this.ngSelectIsFocused = false;
    if (event && event.target) {
      event.target.value = '';
    }
    this.searchTerms = '';
  }

  onBlurChangesSegment(field: string) {
    let eventVal: any;
    let eventNameTitle: any;
    let tripTypeVal = this.getTripTypeVal();

    switch (field) {
      case 'tripType':
        eventNameTitle = 'homepage_tripType';
        eventVal = tripTypeVal;
        break;
      case 'selectedCountries':
        eventNameTitle = `homepage_${tripTypeVal}_travel_country`;
        eventVal = this.selectedCountries;
        break;
      case 'departureDate':
        eventNameTitle = `homepage_${tripTypeVal}_depart_date`;
        eventVal = this.departureDate;
        break;
      case 'returnDate':
        eventNameTitle = `homepage_${tripTypeVal}_return_date`;
        eventVal = this.returnDate;
        break;
      case 'adult1Age':
        eventNameTitle = `homepage_${tripTypeVal}_adult1_age`;
        eventVal = this.age1;
        break;
      case 'adult2Age':
        eventNameTitle = `homepage_${tripTypeVal}_adult2_age`;
        eventVal = this.age2;
        break;
      case 'dependentsCount':
        eventNameTitle = `homepage_${tripTypeVal}_dependent_count`;
        eventVal = this.dependentsCount;
        break;
      case 'addressState':
        eventNameTitle = `homepage_${tripTypeVal}_travel_state`;
        eventVal = this.state;
        break;
      case 'promoCode':
        eventNameTitle = `homepage_${tripTypeVal}_promo_code`;
        eventVal = this.promoCode;
        break;
      case 'membership':
        eventNameTitle = `homepage_${tripTypeVal}_member`;
        eventVal = this.isClubMember;
        break;
    }

    const travelDetails = {
      eventName: eventNameTitle,
      value: eventVal,
    };

    this.segmentService.segmentIdentifyEventNameAndValue(travelDetails);
  }

  getTripTypeVal(): string {
    const tripType = this.singleTripForm.value.tripType;
    if (tripType === 'Single') {
      return 'single';
    } else if (tripType === 'Multiple') {
      return 'amt';
    }
    return 'unknown';
  }
}
