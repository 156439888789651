import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { RacvFooterComponent } from './../../components/racv-footer/racv-footer.component';
import { RacvHeaderComponent } from '../../components/racv-header/racv-header.component';
import { InputTextModule } from 'primeng/inputtext';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import * as _ from 'lodash';
import { DialogModule } from 'primeng/dialog';
import { ValidationService } from '../../services/validation.service';
import { from, Subject, Subscription, takeUntil } from 'rxjs';
import { Rule } from '../../interfaces/rule';
import { CommonModule } from '@angular/common';
import { LocalCacheService } from '../../services/localCache.service';
import { QuoteService } from '../../services/quote.service';
import { StepBarComponent } from '../../components/step-bar/step-bar.component';
import moment from 'moment';
import { environment } from '../../../environments/environment';
import { AgePipe } from '../../utils/age.pipe';
import { PDSService } from '../../services/pds.service';
import { KeyFilterModule } from 'primeng/keyfilter';
import { AmountPipe } from '../../utils/amount.pipe';
import { EmailQuoteComponent } from '../../components/email-quote/email-quote.component';
import { MomentPipe } from '../../utils/moment.pipe';
import { GlobalService } from '../../services/global.service';
import { SegmentService } from '../../services/segment.service';
import { Title, Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-step-three',
  standalone: true,
  imports: [
    CommonModule,
    RacvFooterComponent,
    RacvHeaderComponent,
    RouterLink,
    InputTextModule,
    FormsModule,
    ReactiveFormsModule,
    StepBarComponent,
    DialogModule,
    AgePipe,
    KeyFilterModule,
    AmountPipe,
    EmailQuoteComponent,
    MomentPipe,
  ],
  templateUrl: './step-three.component.html',
  styleUrl: './step-three.component.scss',
  providers: [AgePipe],
})
export class StepThreeComponent implements OnInit, OnDestroy {
  private destroySub$ = new Subject<void>();
  public travelerSummaryForm: FormGroup = new FormGroup({});
  public contactDetailsForm: FormGroup = new FormGroup({});
  public disclaimerMarketingForm: FormGroup = new FormGroup({});
  public validationsList: any[] = [];
  public storedTravelerDetails: any = {};
  public editContactDetails: any = {};
  public storedQuoteDetails: any = {};
  public storedQuoteEnquiry: any = {};
  public storedPriceDetails: any = {};
  public editTravelSummary: any = {};
  public contactDetails: any = {};
  public travelSummary: any = {};
  public errorMessages: any = {};
  public policyDetails: any = {};
  public proceedBtnDisabled: boolean = false;
  public hasDependent: boolean = false;
  public showDisclaimerMessage: boolean = false;
  public hasAdult2: boolean = false;
  primaryReassessment: boolean = false;
  secondaryReassessment: boolean = false;
  showReassessmentModal: boolean = false;
  autoClub = environment.autoClub;
  dependentsInfo = [] as any;
  pdsLink: string = '';
  constructor(
    private formBuilder: FormBuilder,
    private validationService: ValidationService,
    private localCacheService: LocalCacheService,
    private router: Router,
    private quoteService: QuoteService,
    private pdsService: PDSService,
    private globalService: GlobalService,
    private segmentService: SegmentService,
    private titleService: Title,
    private metaService: Meta,
  ) {}

  @ViewChild('saveAndSendEmail')
  saveAndSendEmail!: EmailQuoteComponent;

  openSaveAndSendModal() {
    this.saveAndSendEmail?.openModal();
  }

  ngOnInit(): void {
    if (!this.globalService.checkAndValidatePage(3, false)) {
      this.router.navigate(['/error']);
      return;
    }
    this.storedTravelerDetails =
      this.localCacheService.getSessionStorage('travelerDetails') || {};

    this.storedPriceDetails =
      this.localCacheService.getSessionStorage('selectedCoverDetail') || {};

    this.hasAdult2 =
      this.storedTravelerDetails.adult2 &&
      this.storedTravelerDetails.adult2.firstName
        ? true
        : false;

    this.loadValidations();
    this.loadTravelerDetails();
    if (!this.storedQuoteDetails) {
      this.router.navigate(['/']);
      return;
    }
    this.loadContactDetails();
    this.loadPolicyDetails();
    this.createForm();
    const currentLatestPDS =
      this.localCacheService.getSessionStorage('lastestPDS');
    if (!currentLatestPDS) {
      this.pdsService.getLatestPDS().subscribe((data) => {
        if (data && data.pds && data.pds.length > 0) {
          this.pdsLink = data.pds[0].previewUrl;
        }
      });
    } else {
      this.pdsLink = currentLatestPDS?.previewUrl;
    }
    const pageTitle = this.storedQuoteDetails.isSingleTrip
      ? this.localCacheService.getSessionStorage('domesticCountry')
        ? 'AANT Domestic Travel Insurance Quote'
        : 'AANT International Travel Insurance Quote'
      : 'AANT Multi-Trip Travel Insurance Quote';
    this.titleService.setTitle(pageTitle);
    const metaDescription = this.storedQuoteDetails.isSingleTrip
      ? this.localCacheService.getSessionStorage('domesticCountry')
        ? 'Get a customised domestic travel insurance quote with our easy 3-step process. Quickly find the right coverage for your local travels. Start now to get your quote and secure your next domestic journey.'
        : 'Start your travel journey with a simple 3-step quote process. Get a customised quote for your overseas trips and ensure and travel with added peace of mind.'
      : 'Secure a customised multi-trip travel insurance quote with our straightforward quote process. Ideal for frequent travelers, our coverage options offer protection for multiple trips over 12 months.';
    this.metaService.updateTag({
      name: 'description',
      content: metaDescription,
    });
    const metaKeywords = this.storedQuoteDetails.isSingleTrip
      ? this.localCacheService.getSessionStorage('domesticCountry')
        ? `AANT domestic travel insurance quote
            Domestic travel insurance quote
            Easy domestic insurance quote
            AANT domestic coverage
            Quick domestic insurance quotes
            Get domestic insurance quote online`
        : `AANT international travel insurance quote
            International travel insurance quote
            Easy international insurance quote
            AANT international coverage
            Quick international insurance quotes
            Simple international travel insurance
            International travel coverage quote
            Get international insurance quote online`
      : `AANT multi-trip travel insurance quote
          Multi-trip insurance quote
          Easy multi-trip insurance quote
          AANT multi-trip coverage
          Quick multi-trip insurance quotes
          Simple multi-trip travel insurance
          Get multi-trip insurance quote online`;
    this.metaService.updateTag({
      name: 'keywords',
      content: metaKeywords,
    });
  }

  ngOnDestroy(): void {
    this.destroySub$.next();
    this.destroySub$.complete();
  }

  checkDependents() {
    if (
      this.storedQuoteDetails.travelers.length > 1 &&
      this.storedQuoteDetails.travelers.find(
        (traveler: any) => traveler.role === 'dependent',
      )
    ) {
      this.dependentsInfo = [];
      this.hasDependent = true;
      this.storedQuoteDetails.travelers.map((traveler: any) => {
        if (traveler.role === 'dependent') {
          const tempDependent = {
            firstName: traveler?.firstName ?? '',
            lastName: traveler?.lastName ?? '',
            dateOfBirth: traveler?.dateOfBirth ?? '',
            medicalCover: this.getMedicalCover(traveler),
            medicalConditions: this.getMedicalCondition(traveler),
            medicalStatus: traveler?.medicalInfo?.status,
          };

          this.dependentsInfo.push(tempDependent);
        }
      });
    }
  }

  loadValidations() {
    this.validationService
      .clubValidation()
      .pipe(takeUntil(this.destroySub$))
      .subscribe({
        next: (response) => {
          if (response) {
            this.validationsList = response;
            this.prepareErrorMessages();
          } else {
            console.log('clubValidation response error');
          }
        },
        error: (error) => {
          console.log('clubValidation subscription error', error);
        },
      });
  }

  loadTravelerDetails() {
    this.storedQuoteDetails =
      this.localCacheService.getSessionStorage('quoteDetail') || {};
    this.storedQuoteEnquiry =
      this.localCacheService.getSessionStorage('quoteEnquiry') || {};

    this.storedQuoteDetails?.travelers.forEach((element: any) => {
      if (element.role === 'primary') {
        this.primaryReassessment = element.medicalInfo?.rescore ?? false;
        this.travelSummary.adult1 = {
          firstName: element?.firstName ?? '',
          lastName: element?.lastName ?? '',
          dateOfBirth: element?.dateOfBirth ?? '',
          medicalCover: this.getMedicalCover(element),
          medicalConditions: this.getMedicalCondition(element),
          medicalStatus: element?.medicalInfo?.status,
        };
      } else if (element.role === 'secondary') {
        this.secondaryReassessment = element.medicalInfo?.rescore ?? false;
        this.travelSummary.adult2 = {
          firstName: element?.firstName ?? '',
          lastName: element?.lastName ?? '',
          dateOfBirth: element?.dateOfBirth ?? '',
          medicalCover: this.getMedicalCover(element),
          medicalConditions: this.getMedicalCondition(element),
          medicalStatus: element?.medicalInfo?.status,
        };
      }
    });
    this.checkDependents();
    this.editTravelSummary = _.cloneDeep(this.travelSummary);
  }
  getMedicalCover(element: any) {
    let status = element?.medicalInfo?.status;
    if (status == 'Declined' || status == 'Not Covered') {
      return '0.00';
    }
    return element?.medicalInfo?.medicalPremium;
  }
  getMedicalCondition(element: any) {
    let status = element?.medicalInfo?.status;
    if (status == 'Declined' || status == 'Not Covered') {
      return status;
    }
    return element?.medicalInfo?.medicalConditions ?? '-';
  }
  loadContactDetails() {
    this.storedTravelerDetails =
      this.localCacheService.getSessionStorage('travelerDetails') || {};
    this.contactDetails = {
      email: this.storedTravelerDetails.contactAndAddressDetails?.emailId
        ? this.storedTravelerDetails.contactAndAddressDetails?.emailId
        : '',
      phoneNumber: this.storedTravelerDetails.contactAndAddressDetails
        ?.phoneNumber
        ? this.storedTravelerDetails.contactAndAddressDetails?.phoneNumber
        : '',
      emergencyContact: this.storedTravelerDetails?.emergencyContact
        ? this.storedTravelerDetails?.emergencyContact
        : 'EmergencyContact',
      address: this.storedTravelerDetails.contactAndAddressDetails?.address
        ? this.storedTravelerDetails.contactAndAddressDetails?.address
        : (this.storedTravelerDetails.contactAndAddressDetails?.street
            ? this.storedTravelerDetails.contactAndAddressDetails?.street
            : '') +
          (this.storedTravelerDetails.contactAndAddressDetails?.subUrb
            ? ', ' + this.storedTravelerDetails.contactAndAddressDetails?.subUrb
            : '') +
          (this.storedTravelerDetails.contactAndAddressDetails?.state
            ? ', ' + this.storedTravelerDetails.contactAndAddressDetails?.state
            : '') +
          (this.storedTravelerDetails.contactAndAddressDetails?.postalCode
            ? ', ' +
              this.storedTravelerDetails.contactAndAddressDetails?.postalCode
            : ''),
    };
    this.editContactDetails = _.cloneDeep(this.contactDetails);
  }

  loadPolicyDetails() {
    this.policyDetails = {
      quoteNumber: this.storedQuoteDetails.quoteNumber,
      destination: this.getDestination(
        this.storedQuoteEnquiry.travelDestination,
      ),
      tripType: this.storedQuoteEnquiry.tripType,
      depart: moment(this.storedQuoteEnquiry.depart).format("DD MMMM' YYYY"),
      return: moment(this.storedQuoteEnquiry.return).format("DD MMMM' YYYY"),
      isCruise: this.storedQuoteDetails.isCruise === true ? 'Yes' : 'No',
      isSki: this.storedQuoteDetails.isSking === true ? 'Yes' : 'No',
      duration: this.calculateDaysBetweenDates(
        this.storedQuoteEnquiry.return,
        this.storedQuoteEnquiry.depart,
      ),
      ages: this.agesBuilding(),
      dependentsCount: this.storedQuoteDetails.dependentsCount,
      productType: this.storedQuoteDetails.productType,
      productAlias: this.storedQuoteDetails.productAlias,
      totalCoverPolicy: this.storedQuoteDetails.premiums.product,
      excess: this.storedQuoteDetails.premiums.excess,
      excessLevel: this.storedPriceDetails.selectedPrice.excess,
      stampDuty: this.storedQuoteDetails.premiums.stampDuty,
      additionalPremium: this.storedQuoteDetails.premiums.medical,
      membershipDiscount: this.storedQuoteDetails?.premiums?.membershipDiscount,
      promoCode: this.storedQuoteDetails.promoCode,
      promoCodeDisc: this.storedQuoteDetails.premiums.promoCodeDiscount,
      gst: this.storedQuoteDetails.premiums.gst,
      totalAmount: this.storedQuoteDetails.premiums.totalCost,
      cruiseExpense: this.storedQuoteDetails.premiums.cruise,
      skiExpense: this.storedQuoteDetails.premiums.skiing,
      isSingleTrip: this.storedQuoteDetails.isSingleTrip,
    };
    console.log('Policy Details:', this.policyDetails);
  }

  createForm() {
    this.travelerSummaryForm = this.formBuilder.group(
      {
        adult1: this.formBuilder.group({
          firstName: [
            { value: this.editTravelSummary.adult1.firstName, disabled: false },
            [
              this.nameShouldNotExceed50AndNotContainNumbers(
                'Primary Traveller',
                'First Name',
              ),
            ],
            ,
          ],
          lastName: [
            { value: this.editTravelSummary.adult1.lastName, disabled: false },
            [
              this.nameShouldNotExceed50AndNotContainNumbers(
                'Primary Traveller',
                'Last Name',
              ),
            ],
            ,
          ],
          age: [
            {
              value: this.calculateAge(
                this.editTravelSummary.adult1.dateOfBirth,
              ),
              disabled: true,
            },
            Validators.required,
          ],
          dateOfBirth: [
            {
              value: this.editTravelSummary.adult1.dateOfBirth,
              disabled: true,
            },
            Validators.required,
          ],
          medicalCover: [
            {
              value: this.editTravelSummary.adult1.medicalCover ?? '0.00',
              disabled: true,
            },
          ],
          medicalConditions: [
            {
              value: this.editTravelSummary.adult1.medicalConditions ?? '-',
              disabled: true,
            },
          ],
        }),
        adult2: this.formBuilder.group({
          firstName: [
            {
              value: this.editTravelSummary.adult2?.firstName,
              disabled: false,
            },
            [
              this.nameShouldNotExceed50AndNotContainNumbers(
                'Secondary Traveller',
                'First Name',
              ),
            ],
            ,
          ],
          lastName: [
            { value: this.editTravelSummary.adult2?.lastName, disabled: false },
            [
              this.nameShouldNotExceed50AndNotContainNumbers(
                'Secondary Traveller',
                'Last Name',
              ),
            ],
            ,
          ],
          age: [
            {
              value: this.calculateAge(
                this.editTravelSummary.adult2?.dateOfBirth,
              ),
              disabled: true,
            },
          ],
          dateOfBirth: [
            {
              value: this.editTravelSummary.adult2?.dateOfBirth,
              disabled: true,
            },
          ],
          medicalCover: [
            {
              value: this.editTravelSummary.adult2?.medicalCover ?? '0.00',
              disabled: true,
            },
          ],
          medicalConditions: [
            {
              value: this.editTravelSummary.adult2?.medicalConditions ?? '-',
              disabled: true,
            },
          ],
        }),
        dependents: this.formBuilder.array([]),
      },
      { validators: this.checkTravellers },
    );

    this.contactDetailsForm = this.formBuilder.group({
      email: [
        { value: this.editContactDetails.email, disabled: false },
        [Validators.required, Validators.email],
      ],
      phoneNumber: [
        { value: this.editContactDetails.phoneNumber, disabled: false },
        [Validators.required],
      ],
      address: [{ value: this.editContactDetails.address, disabled: true }],
      emergencyContact: this.formBuilder.group({
        firstName: [
          {
            value: this.editContactDetails.emergencyContact.firstName,
            disabled: false,
          },
          [],
        ],
        lastName: [
          {
            value: this.editContactDetails.emergencyContact.lastName,
            disabled: false,
          },
          [],
        ],
        emailId: [
          {
            value: this.editContactDetails.emergencyContact.emailId,
            disabled: false,
          },
          [Validators.email],
        ],
        phoneNumber: [
          {
            value: this.editContactDetails.emergencyContact.phoneNumber,
            disabled: false,
          },
          [Validators.maxLength(20)],
        ],
      }),
    });

    this.disclaimerMarketingForm = this.formBuilder.group({
      privacy: ['', Validators.required],
      marketing: ['', Validators.required],
    });

    this.showAdult2Section(this.hasAdult2);
    this.showDependentSection();
  }

  get dependents(): FormArray {
    return this.travelerSummaryForm.get('dependents') as FormArray;
  }

  showAdult2Section(hasAdult2: boolean) {
    if (hasAdult2) {
      const adult2FormGroup = this.formBuilder.group({
        firstName: [
          {
            value: this.editTravelSummary.adult2?.firstName ?? '',
            disabled: true,
          },
          [
            this.nameShouldNotExceed50AndNotContainNumbers(
              'Secondary Traveller',
              'First Name',
            ),
          ],
          ,
        ],
        lastName: [
          {
            value: this.editTravelSummary.adult2?.lastName ?? '',
            disabled: true,
          },
          [
            this.nameShouldNotExceed50AndNotContainNumbers(
              'Secondary Traveller',
              'Last Name',
            ),
          ],
        ],
        dateOfBirth: [
          {
            value: this.editTravelSummary.adult2?.dateOfBirth ?? '',
            disabled: true,
          },
          Validators.required,
        ],
        two_med_con: new FormControl(
          this.editTravelSummary.adult2?.one_med_con ?? '',
        ),
      });
      this.travelerSummaryForm.addControl('adult2', adult2FormGroup);
    } else {
      this.travelerSummaryForm.removeControl('adult2');
    }
  }

  showDependentSection() {
    if (this.hasDependent) {
      this.dependentsInfo.map((traveler: any) => {
        const dependentsFormGroup = this.formBuilder.group({
          firstName: [
            { value: traveler.firstName, disabled: false },
            [
              this.nameShouldNotExceed50AndNotContainNumbers(
                'Dependent',
                'First Name',
              ),
            ],
          ],
          lastName: [
            { value: traveler.lastName, disabled: false },
            [
              this.nameShouldNotExceed50AndNotContainNumbers(
                'Dependent',
                'Last Name',
              ),
            ],
          ],
          age: [
            {
              value: this.calculateAge(traveler.dateOfBirth),
              disabled: true,
            },
          ],
          dateOfBirth: [
            { value: traveler.dateOfBirth, disabled: true },
            Validators.required,
          ],
          medicalCover: [
            {
              value: traveler.medicalPremium ?? '0.00',
              disabled: true,
            },
          ],
          medicalConditions: [
            {
              value: traveler.medicalConditions ?? '-',
              disabled: true,
            },
          ],
        });

        this.dependents.push(dependentsFormGroup);
      });
    }
  }

  onUpdateTravelSummary(event: any) {
    let newTravelerDetails =
      this.localCacheService.getSessionStorage('travelerDetails');
    let newFinalTravelerDetails = this.localCacheService.getSessionStorage(
      'finalTravelerDetails',
    );
    if (this.travelerSummaryForm.valid) {
      //SET THE EDITTED VALUES TO THE SESSION - ADULT2
      newTravelerDetails.adult1.firstName =
        this.travelerSummaryForm.get('adult1.firstName')?.value;
      newTravelerDetails.adult1.lastName =
        this.travelerSummaryForm.get('adult1.lastName')?.value;
      newTravelerDetails.adult1.dateOfBirth =
        this.travelerSummaryForm.get('adult1.dateOfBirth')?.value;
      if (this.hasAdult2) {
        //SET THE EDITTED VALUES TO THE SESSION - ADULT2
        newTravelerDetails.adult2.firstName =
          this.travelerSummaryForm.get('adult2.firstName')?.value;
        newTravelerDetails.adult2.lastName =
          this.travelerSummaryForm.get('adult2.lastName')?.value;
        newTravelerDetails.adult2.dateOfBirth =
          this.travelerSummaryForm.get('adult2.dateOfBirth')?.value;
      }

      if (this.hasDependent) {
        //SET THE EDITTED VALUES TO THE SESSION - DEPENDENTS
        this.dependents.controls.forEach((dependent: any, index: number) => {
          newTravelerDetails.dependents[index].firstName =
            dependent.get('firstName')?.value;
          newTravelerDetails.dependents[index].lastName =
            dependent.get('lastName')?.value;
          newTravelerDetails.dependents[index].dateOfBirth =
            dependent.get('dateOfBirth')?.value;
        });
      }
      this.localCacheService.saveSessionStorage(
        'travelerDetails',
        newTravelerDetails,
      );
      this.localCacheService.saveSessionStorage(
        'finalTravelerDetails',
        newFinalTravelerDetails,
      );

      this.quoteService
        .submitQuote(newTravelerDetails, 'update-traveler-detail')
        .subscribe({
          next: (response) => {
            if (!response.error) {
              console.log('remove dependent update-traveler-detail', response);
              this.localCacheService.saveSessionStorage(
                'quoteDetail',
                response,
              );
              this.loadTravelerDetails();
            }
          },
          error: (error) => {
            console.log('update-traveler-detail', error);
          },
        });
    } else {
      event.preventDefault();
      this.travelerSummaryForm.markAllAsTouched();
    }
  }

  onCancelUpdateTravelSummary() {
    this.editTravelSummary = _.cloneDeep(this.travelSummary);
    this.createForm();
  }

  onUpdateContactDetails(event: any) {
    if (this.contactDetailsForm.valid) {
      let newContactDetails =
        this.localCacheService.getSessionStorage('travelerDetails');
      newContactDetails.contactAndAddressDetails.emailId =
        this.contactDetailsForm.get('email')?.value;
      newContactDetails.contactAndAddressDetails.phoneNumber =
        this.contactDetailsForm.get('phoneNumber')?.value;
      newContactDetails.contactAndAddressDetails.address =
        this.contactDetailsForm.get('address')?.value;
      newContactDetails.emergencyContact.firstName =
        this.contactDetailsForm.get('emergencyContact.firstName')?.value;
      newContactDetails.emergencyContact.lastName = this.contactDetailsForm.get(
        'emergencyContact.lastName',
      )?.value;
      newContactDetails.emergencyContact.emailId = this.contactDetailsForm.get(
        'emergencyContact.emailId',
      )?.value;
      newContactDetails.emergencyContact.phoneNumber =
        this.contactDetailsForm.get('emergencyContact.phoneNumber')?.value;

      this.localCacheService.saveSessionStorage(
        'travelerDetails',
        newContactDetails,
      );

      this.quoteService
        .submitQuote(newContactDetails, 'update-traveler-detail')
        .subscribe({
          next: (response) => {
            if (!response.error) {
              console.log('update-contact-detail', response);
              this.localCacheService.saveSessionStorage(
                'quoteDetail',
                response,
              );
              this.loadContactDetails();
            }
          },
          error: (error) => {
            console.log('update-contact-detail', error);
          },
        });
    } else {
      event.preventDefault();
      this.contactDetailsForm.markAllAsTouched();
    }
  }

  onCancelUpdateContactDetails() {
    this.editContactDetails = _.cloneDeep(this.contactDetails);
    this.createForm();
  }

  prepareErrorMessages() {
    this.validationsList.forEach((validation) => {
      if (!this.errorMessages[validation.field]) {
        this.errorMessages[validation.field] = {};
      }

      validation.rules.forEach((rule: Rule) => {
        this.errorMessages[validation.field][rule.type] = rule.message;
      });
    });
  }

  calculateAge(dateOfBirth: any) {
    const birthDate = moment(dateOfBirth, 'DD/MM/YYYY').toDate();
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  confirmProceedPayment() {
    if (this.primaryReassessment || this.secondaryReassessment) {
      this.showReassessmentModal = true;
      return;
    }
    if (this.disclaimerMarketingForm.valid && !this.proceedBtnDisabled) {
      if (this.storedQuoteDetails && this.storedQuoteDetails.quoteId) {
        this.segmentService.segmentConfirmTravelDetails();
        this.quoteService
          .submitQuote(
            this.disclaimerMarketingForm.value,
            'step-three-marketing-flag',
          )
          .subscribe({
            next: (response) => {
              if (!response.error) {
                console.log('step-three-marketing-flag', response);
                this.localCacheService.saveSessionStorage(
                  'quoteDetail',
                  response,
                );
                this.quoteService
                  .approveQuote(this.storedQuoteDetails.quoteId)
                  .pipe(takeUntil(this.destroySub$))
                  .subscribe({
                    next: (response) => {
                      if (response?.isApproved) {
                        this.redirectToPaymentPage();
                      } else {
                        console.log('approveQuote response error');
                      }
                    },
                    error: (error) => {
                      console.log('approveQuote subscription error', error);
                    },
                  });
              }
            },
            error: (error) => {
              console.log('update-contact-detail', error);
            },
          });
      }
    } else {
      this.disclaimerMarketingForm.markAllAsTouched();
    }
  }

  getDestination(destinations: any) {
    let destination = '';
    if (destinations && destinations.length > 0) {
      destinations.forEach((dest: any) => {
        destination += dest.countryName + ', ';
      });
    }
    return destination.slice(0, -2);
  }

  calculateDaysBetweenDates(dateStr1: string, dateStr2: string) {
    const depDate = moment(dateStr1);
    const rtnDate = moment(dateStr2);
    return depDate.diff(rtnDate, 'days');
  }

  onDiscalimerSelection(choice: boolean) {
    if (!choice) {
      this.showDisclaimerMessage = true;
      this.proceedBtnDisabled = true;
    } else {
      this.showDisclaimerMessage = false;
      this.proceedBtnDisabled = false;
    }
  }

  redirectToPaymentPage(): void {
    this.globalService.checkAndValidatePage(3, true);
    this.router.navigate(['/quotes-steps-four']);
  }

  agesBuilding(): string {
    if (
      this.storedQuoteDetails.ages.length === 2 &&
      this.storedQuoteDetails.ages[1] === 0
    ) {
      return this.storedQuoteDetails.ages[0].toString();
    } else if (
      this.storedQuoteDetails.ages.length === 2 &&
      this.storedQuoteDetails.ages[1] > 0
    ) {
      return this.storedQuoteDetails.ages
        .map((age: any) => age.toString())
        .join(', ');
    }
    return '';
  }

  downloadPDS() {
    let isMobileView = window.innerWidth < 768;
    const button = document.getElementById('downloadPDSButton');

    if (isMobileView) {
      if (button) {
        button.classList.remove('hidden');
      }
    }

    this.pdsService.downloadLatestPDSPDF();
    if (isMobileView) {
      if (button) {
        setTimeout(() => {
          button.classList.add('hidden');
        }, 1000);
      }
    }
  }

  nameShouldNotExceed50AndNotContainNumbers(
    travellerType: string,
    field: string,
  ) {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value === undefined) {
        return null;
      }
      if (!control.value.trim())
        return {
          nameValidationMessage: `Please enter the ${field} of the ${travellerType}`,
        };

      if (control.value.trim().length > 50) {
        if (this.containsDecimalNumbers(control.value.trim())) {
          setTimeout(() => {
            let value = control.value.replace(/\d+/g, '');
            value = value.replace(/[^a-zA-Z-\s',’]/g, '');
            control.setValue(value);
          }, 150);
        }
        return {
          nameValidationMessage: `${field} cannot have numbers and cannot be longer than 50 characters.`,
        };
      }
      if (this.isNumber(control.value.trim())) {
        setTimeout(() => {
          let value = control.value.replace(/\d+/g, '');
          value = value.replace(/[^a-zA-Z-\s',’]/g, '');
          control.setValue(value);
          control.setErrors({
            nameValidationMessage: `${field} cannot have numbers and cannot be longer than 50 characters.`,
          });
        }, 150);
        return null;
      }

      if (
        this.containsDecimalNumbers(control.value.trim()) ||
        !this.isValidName(control.value.trim())
      ) {
        setTimeout(() => {
          let value = control.value.replace(/\d+/g, '');
          value = value.replace(/[^a-zA-Z-\s',’]/g, '');
          control.setValue(value);
        }, 150);
        return null;
      }
      // control.setErrors(null);
      return null;
    };
  }
  isValidName(str: string) {
    const regex = /^[a-zA-Z-\s',’]+$/;
    const testResult = regex.test(str);
    return testResult;
  }
  isNumber(str: any) {
    return !isNaN(str) && str.trim() !== '';
  }
  containsDecimalNumbers(str: string) {
    return /\d+(\.\d+)?/.test(str);
  }

  onBlurNameField(form: any, field: string) {
    let value = form.get(field).value.trim();

    if (this.containsDecimalNumbers(value)) {
      value = value.replace(/\d+/g, '');
    }

    const error = form.get(field).errors;

    form.get(field).patchValue(value);
    if (error && error.nameValidationMessage) {
      form.get(field).setErrors(error);
    }
  }

  checkTravellers(group: FormGroup) {
    const adult1 = group.get('adult1')?.value;
    const adult2 = group.get('adult2') ? group.get('adult2')?.value : null;
    const adult1Dob = group.get('adult1.dateOfBirth')?.value;
    const adult2Dob = group.get('adult2.dateOfBirth')?.value;
    if (adult2) {
      // Convert all values to lowercase for case-insensitive comparison
      const a1FirstName = adult1.firstName
        ? adult1.firstName.toLowerCase()
        : '';
      const a1LastName = adult1.lastName ? adult1.lastName.toLowerCase() : '';

      const a2FirstName = adult2.firstName
        ? adult2.firstName.toLowerCase()
        : '';
      const a2LastName = adult2.lastName ? adult2.lastName.toLowerCase() : '';

      // Check if all fields for both travellers are initially blank
      if (
        a1FirstName === '' &&
        a1LastName === '' &&
        adult1Dob === '' &&
        a2FirstName === '' &&
        a2LastName === '' &&
        adult2Dob === ''
      ) {
        return null; // Skip validation since all fields are blank
      }

      // Proceed with the usual checks if the fields are not all blank
      if (
        a1FirstName === a2FirstName &&
        a1LastName === a2LastName &&
        adult1Dob === adult2Dob
      ) {
        return { sameDetailsError: true };
      }
    }
    return null;
  }

  onBlurMobileNumber() {
    const phoneNumber = this.contactDetailsForm.get('phoneNumber');

    if (phoneNumber?.value && phoneNumber?.value.length < 10) {
      phoneNumber?.setErrors({
        phoneNumberInvalid: true,
      });
    }
  }

  triggerSegmentEvent(field: string) {
    let eventVal: any;
    let eventNameTitle: any;

    switch (field) {
      case 'disclaimerYes':
        eventNameTitle = 'step_three_disclaimer';
        eventVal = 'Yes';
        break;
      case 'disclaimerNo':
        eventNameTitle = 'step_three_disclaimer';
        eventVal = 'No';
        break;
      case 'marketingConsentYes':
        eventNameTitle = 'step_three_marketing_consent';
        eventVal = 'Yes';
        break;
      case 'marketingConsentNo':
        eventNameTitle = 'step_three_marketing_consent';
        eventVal = 'No';
        break;
    }

    const travelDetails = {
      eventName: eventNameTitle,
      value: eventVal,
    };

    this.segmentService.segmentIdentifyEventNameAndValue(travelDetails);
  }
}
